import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import "./talking-with-your-doctor.scss";
import Banner from "../components/banner";
import bannerImg from "../images/talking-with-your-doctor/talking-with-your-doctor-img.jpg";
import bannerImgMobile from "../images/talking-with-your-doctor/talking-with-your-doctor-mobile.png";
import { Col, Row } from "react-grid-system";
import buttonExternalIcon from "../images/icons/external-icon.svg";
import TwoCol from "../components/twoCol/twoCol";
import twoColImg1 from "../images/talking-with-your-doctor/a-man-laughing.jpg";
import twoColImg2 from "../images/talking-with-your-doctor/doctor.jpg";
import twoColImg3 from "../images/talking-with-your-doctor/happy-patient.jpg";
// import drSimpsonThumbnail from "../images/talking-with-your-doctor/gr-simpson-thumbnail.jpg";
import TileIcon from "../components/tileIcon";
import messageIcon from "../images/icons/message-icon.svg";
import MoveInfo from "../components/moveInfo";
import downloadIcon from "../images/icons/download-icon.svg";
// import WistiaPlayerModal from "../components/WistiaPlayer";
import ExternalLink from "../components/modals/externalLink";
import Button from "../components/button";
import StickyButton from "../components/stickyButton";

const TalkingWithYourDoctorPage = () => {
  return (
    <HcpLayout pageClass="talking-with-your-doctor-page">
      <section className="hero-banner">
        <Banner
          extraClass="gradient-overlay"
          bannerImg={bannerImg}
          bannerImgMobile={bannerImgMobile}
          bannerImgAlt="Female doctor and a male patient sitting in a exam room pointing to a tablet"
          bannerTitle="Talking with your doctor about diabetic nerve pain of the feet"
          nextSecId="#talking-info"
          tagText="Actor portrayals."
        />
        <StickyButton />
      </section>

      <section className="info-block" id="talking-info">
        <div className="centered-content">
          <div className="content">
            <h2 className="h1">Partner with your doctor</h2>
            <Row>
              <Col lg={6}>
                <p>
                  Your doctor is your best ally when it comes to addressing your
                  diabetic nerve pain of the feet. Together, you can explore the
                  way to manage foot pain that makes the most sense for you.
                </p>
              </Col>
              <Col lg={6}>
                <p>
                  The sooner you speak with your doctor about your painful feet
                  and outline your treatment goals, the sooner you can get on a
                  path to becoming more active, and doing more of the things you
                  love.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center"
            image={twoColImg1}
            imageAlt="Man in a winter hat walking beside a lake and the mountains smiling"
            description={`<p>When speaking to your healthcare provider, you may need to request a referral to a specialist to get a proper diagnosis and treatment for your condition.</p>`}
          >
            <div className="dot-list">
              <span>Specialists include:</span>
              <ul>
                <li>
                  <strong>Neurologists</strong> - treat conditions related to
                  the nerves, brain, and spinal cord
                </li>
                <li>
                  <strong>Podiatrists</strong> - treat problems with the feet
                </li>
                <li>
                  <strong>Interventional pain management specialists</strong> -
                  doctors who specialize in the management of pain
                </li>
                <li>
                  <strong>Endocrinologists</strong> - treat conditions related
                  to hormones
                </li>
              </ul>
            </div>
          </TwoCol>
        </div>
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center reverse"
            image={twoColImg2}
            imageAlt="Doctor and patient sitting and reviewing a clipboard chart"
            heading={`Get help talking to your doctor about your symptoms`}
            description={`<p>Once your doctor learns about the pain and other symptoms you have, they can help you choose the right strategy to manage them.</p>`}
            btnclass="btn-primary btn-md border-32"
            btnTitle="Download Now"
            btnIcon={downloadIcon}
            btnUrl="/pdf/unbranded-doctor-discussion-guide.pdf"
            newTab
            className="tag-right"
            tagText="Actor portrayals."
          ></TwoCol>
        </div>
      </section>

      {/* <section className="video-block">
        <div className="centered-content">
          <WistiaPlayerModal
            thumbnail={drSimpsonThumbnail}
            thumbnailAlt="Male doctor sitting at his desk giving an interview"
            title="“Bring it to the attention of your family doctor.”"
            authorName="Dr. Simpson"
            videoId="el7t2m23ej"
          />
        </div>
      </section> */}

      <section className="move-block">
        <MoveInfo />
      </section>

      <section className="two-col__block">
        <div className="centered-content">
          <TwoCol
            extraClass="align-center reverse"
            image={twoColImg3}
            imageAlt="Female woman in workout clothing standing confidently on a walking path in the woods"
            heading={`A direct way to relieve diabetic nerve pain of the feet`}
            description={`<p>Learn about a unique diabetic foot pain treatment.</p>`}
          >
            <div className="btn-wrap">
              <ExternalLink
                title="Discover a Targeted Treatment"
                extraClass="modal-lg"
                href="https://www.qutenza.com/dpn/about-qutenza/"
              >
                <Button
                  className="btn-primary btn-md border-32"
                  btnTitle="Discover a Targeted Treatment"
                  btnIcon={buttonExternalIcon}
                />
              </ExternalLink>
            </div>
          </TwoCol>
        </div>
      </section>

      <section className="target-block target-block--alt">
        <div className="centered-content">
          <Row className="row">
            <Col lg={6} className="col-left">
              <TileIcon
                extraClass="tile-icon--alt"
                url="/get-updates/"
                icon={messageIcon}
                title="Get Updates"
                desc={`<p>Sign up to get educational and personalized resources you can share with your family, friends, and healthcare provider.</p>
                            `}
              />
            </Col>
          </Row>
        </div>
      </section>
    </HcpLayout>
  );
};

export default TalkingWithYourDoctorPage;

export const Head = () => <Seo title="Talking with your doctor" />;
